import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from '../models/result';
import { BehaviorSubject, Observable, catchError, finalize, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PartnerDetails } from '../models/partner-details';
import { MatDialog } from '@angular/material/dialog';
import { UserDto } from '../models/user.dto';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  readonly API_URL = `${environment.apiUri}`;
  partnerUrl: string = this.API_URL + '/Partners';
  usersUrl: string = this.API_URL + '/users';

  partnerDetails: PartnerDetails | null = null;
  userDetails: UserDto | null = null;

  private partnerDetailsSubject = new BehaviorSubject<PartnerDetails | 'completed' | null>(null);
  partnerDetails$ = this.partnerDetailsSubject.asObservable();


  constructor(
    private http: HttpClient,
    public dialog: MatDialog
  ) { }


  getPartnerByUserId(userId: string): Observable<Result<PartnerDetails>> {
    const url = `${this.partnerUrl}/user/${userId}`;
    return this.http.get<Result<PartnerDetails>>(url).pipe(
      tap((result) => {
        if (result && result.isSuccess) {
          this.partnerDetails = result.data;
          this.partnerDetailsSubject.next(result.data);
        }
      }),
      catchError(this.handleError),
      finalize(() => {
        this.partnerDetailsSubject.next('completed');
      })
    );
  }

  getUserById(userId: string): Observable<Result<UserDto>> {
    const url = `${this.usersUrl}/${userId}`;
    return this.http.get<Result<UserDto>>(url).pipe(
      tap((result) => {
        if (result && result.isSuccess) {
          this.userDetails = result.data;
        }
      }),
      catchError(this.handleError),
      finalize(() => {
        this.partnerDetailsSubject.next('completed');
      })
    );
  }

  // helpers
  private handleError(error: any) {
    // this.loggingService.logException(error);
    return throwError(() => error);
  }
}
