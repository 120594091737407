import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogPresets, Preset } from './confirmation-data';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent {
  key = '';

  private presets: ConfirmationDialogPresets = {
    'confirm': {
      icon: 'help',
      title: this.translate.instant('dialogs.are-you-sure-you-want-to-book-the-meeting'),
      cancelBtn: this.translate.instant('shared.cancel'),
      saveBtn: this.translate.instant('shared.book'),
      darkTheme: true,
      errorDialog: false,
    },
    'warning': {
      icon: 'report_problem',
      title: this.translate.instant('dialogs.fill-in-all-required-fields-to-book-the-meeting'),
      cancelBtn: this.translate.instant('shared.ok'),
      saveBtn: '',
      darkTheme: true,
      errorDialog: true,
    },
    'error': {
      icon: 'error',
      title: this.translate.instant('dialogs.something-went-wrong'),
      cancelBtn: this.translate.instant('shared.ok'),
      saveBtn: '',
      darkTheme: true,
      errorDialog: true,
    },
    'success': {
      icon: 'assignment_turned_in',
      title: this.translate.instant('dialogs.the-customer-will-be-informed-about-the-meeting-via-email'),
      cancelBtn: this.translate.instant('auth.log-me-out'),
      saveBtn: this.translate.instant('dialogs.perfect-now-book-another-meeting'),
      darkTheme: false,
      errorDialog: false,
    },
    'success-no-mail': {
      icon: 'assignment_turned_in',
      title: this.translate.instant('dialogs.booking-created-without-email'),
      cancelBtn: this.translate.instant('auth.log-me-out'),
      saveBtn: this.translate.instant('dialogs.perfect-now-book-another-meeting'),
      darkTheme: false,
      errorDialog: false,
    },
    'success-so-ticket': {
      icon: 'assignment_turned_in',
      title: this.translate.instant('dialogs.so-ticket-created'),
      cancelBtn: this.translate.instant('auth.log-me-out'),
      saveBtn: this.translate.instant('dialogs.perfect-now-book-another-meeting'),
      darkTheme: false,
      errorDialog: false,
    },
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private translate: TranslateService
  ) {
    this.key = data;
  }

  getPreset(): Preset {
    return this.presets[this.key];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick() {
    this.dialogRef.close(true);
  }
}
