<!-- CALENDAR -->
<div class="calendar-container">

  <div style="display: flex; flex-direction: column;">

    <div style="display: flex; justify-content: space-between; align-items: center;">
      <p class="calendar-title">{{ 'booking.choose-date-and-time' | translate }}</p>
      <mat-icon style="font-size: 19px; margin-right: 3px; cursor: pointer;" (click)="close()">clear</mat-icon>
    </div>

    <button mat-button style="width: 100px; margin: auto; color: #17A2B8;" (click)="goToToday()">{{ 'shared.today' | translate }}</button>

  </div>

  <ngb-datepicker #calendarDp [dayTemplate]="customDay" [minDate]="minDate" (dateSelect)="onDateSelect($event)"
  (navigate)="onCalendarNavigate($event)" [(ngModel)]="calendarModel">
  </ngb-datepicker>

  <ng-template #customDay
    let-date
    let-currentMonth="currentMonth"
    let-selected="selected"
    let-disabled="disabled"
    let-focused="focused">

    <div class="custom-day"
      [class.available]="isAvailable(date)"
      [class.disabled]="!isAvailable(date)"

      [class.weekend]="isWeekend(date)"
      [class.today]="isToday(date)"
      [class.focused]="focused"
      [class.selected]="selected"
      [class.hidden]="date.month !== currentMonth"
      [class.text-muted]="disabled"
    >
      {{ date.day }}
    </div>

  </ng-template>

  <mat-spinner *ngIf="calendarLoading" [diameter]="25" style="margin: auto;"></mat-spinner>

  <div class="time-container">

    <mat-chip-listbox *ngIf="avalibleTimes">
      @for (time of avalibleTimes; track time) {
        <mat-chip-option (click)="onTimeSelect(time)"
        [selected]="isTimeSelected(time.slot.startTime)"
        title="{{converStringDateToTimeOnly(time.slot.startTime)+' - '+converStringDateToTimeOnly(time.slot.endTime)}}">
        {{converStringDateToTimeOnly(time.slot.startTime)}}</mat-chip-option>
      }
    </mat-chip-listbox>

    <br>

    <button mat-flat-button class="save-btn inter-700" (click)="save()"
      [ngClass]="{'save-btn-disabled': (!selectedTime || !selectedDate)}"
      [disabled]="(!selectedTime || !selectedDate)"> {{ 'shared.save' | translate }} </button>

  </div>

</div>
