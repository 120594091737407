export const environment = {
  production: false,

  apiUri: 'https://fonus-test.azure-api.net/ktc_booking/v3/api',
  // apiUri: 'http://localhost:5106/api',
  apimKeyHeader: 'Ocp-Apim-Subscription-Key',
  apimKey: '9db9f4b0ac1345ccbd8eccdff756f888',

  // B2C
  apiScope: ['https://testpartnerportalb2c.onmicrosoft.com/8b64a87f-3f82-47e0-b623-601a848e83ce/api_access'],
  azureAdAuthConfig: {
    clientId: '8b64a87f-3f82-47e0-b623-601a848e83ce', // app registration in B2C

    authorityDomain: 'testpartnerportalb2c.b2clogin.com',
    authority: 'https://testpartnerportalb2c.b2clogin.com/testpartnerportalb2c.onmicrosoft.com/B2C_1_sign_up_sign_in'
  },

  defaultPartTypeForFirstClient: 'b2c1d8c1-893a-4152-89bb-6b0401ca4ed2',
  defaultPartTypeForAdditionalClients: 'dcdf2be1-22e1-460a-8ac4-ac473157cf11', // beställare

  juristforsakringId: 'a56e057d-2803-43e4-8dff-6a2d582acc59',
  defaultSourceId: '6823ab45-0703-4426-ade0-c09d2d8567ed',

  fonusPartnerId: 'cadbca4c-f999-4cb3-a52a-474ba435d874',

  vardnadBoendeUmgangeCaseTypeId: 'd1626797-2807-4750-832f-0de3b86ed301',
  vardnadBoendeUmgangeDefaultPartTypeId: '76ad5b5e-6603-4e45-8d15-0d1310053b3b', // motpart

  bouppteckningCaseTypeId: 'c9734a5c-3bb7-4214-96dc-ce2278155700',
  bouppteckningDefaultPartTypeId: 'dcdf2be1-22e1-460a-8ac4-ac473157cf11', // beställare

  defaultWayOfContactId: 'a10518f9-d904-42b8-9821-d42d29d1e5ce',
};

