<div class="confirmation-container">

  <mat-dialog-content>
    <h1 mat-dialog-title>Email Details</h1>
    <div mat-dialog-content>
      <p><strong>Email:</strong> {{ data.email }}</p>
      <p><strong>Subject:</strong> {{ data.subject }}</p>
      <p><strong>Body:</strong></p>
      <div [innerHTML]="data.body"></div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: center; gap: 25px;">
    <button mat-button class="action-btn cancel-btn-light" (click)="onNoClick()">Don't send</button>
    <button mat-button cdkFocusInitial class="action-btn book-btn send-btn-light" (click)="onSaveClick()">Send</button>
  </mat-dialog-actions>

</div>
