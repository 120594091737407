
<div class="confirmation-container" [ngClass]="getPreset().darkTheme ? 'bg-dark-theme' : 'bg-light-theme'">
  <mat-dialog-content>
    <mat-icon class="title-icon" [ngClass]="getPreset().darkTheme ? 'color-dark-theme' : 'color-light-theme'">{{getPreset().icon}}</mat-icon>
    <p class="info" [ngClass]="getPreset().darkTheme ? 'info-color-dark-theme' : 'info-color-light-theme'">{{getPreset().title}}</p>
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: center; gap: 25px;">
    <button mat-button class="action-btn"
    [ngClass]="getPreset().darkTheme ? 'cancel-btn-dark' : 'cancel-btn-light'" (click)="onNoClick()">{{getPreset().cancelBtn}}</button>
    <button mat-button cdkFocusInitial class="action-btn book-btn"
    [ngClass]="getPreset().darkTheme ? 'book-btn-dark' : 'book-btn-light'" *ngIf="!getPreset().errorDialog" (click)="onSaveClick()">{{getPreset().saveBtn}}</button>
  </mat-dialog-actions>
</div>
