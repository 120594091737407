import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

export const b2cPolicies = {
  names: {
      signIn: "B2C_1_sign_up_sign_in",
  },
  authorities: {
      signUpSignIn: {
          authority: environment.azureAdAuthConfig.authority,
      }
  },
  authorityDomain: environment.azureAdAuthConfig.authorityDomain
};


export const msalConfig: Configuration = {
  auth: {
    clientId: environment.azureAdAuthConfig.clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/'
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
         //console.log(message);
      },
      logLevel: LogLevel.Info,
      piiLoggingEnabled: false
    }
  }
};

export const loginRequest = {
  scopes: []
};

