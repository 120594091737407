import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailConfirmationDto } from '../../models/email-confirmation.dto';

@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrl: './email-preview.component.scss'
})
export class EmailPreviewComponent {
  constructor(
    public dialogRef: MatDialogRef<EmailPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmailConfirmationDto
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick() {
    this.dialogRef.close(true);
  }
}
