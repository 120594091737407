import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  openSnackBar(message: string | HttpErrorResponse, type: 'success' | 'error' | 'warning' = 'error', action: string = 'X', durationInSec: number = 15,) {
    let formattedMessage: string;

    if (message instanceof HttpErrorResponse) {
      formattedMessage = this.showErrorMessage(message);
      type = 'error'; // Assume error type if it's an HttpErrorResponse
    } else {
      formattedMessage = message;
    }

    this.snackBar.open(formattedMessage, action, {
      duration: durationInSec * 1000,
      panelClass: ['snackbar-' + type],
      verticalPosition: 'top',
    });
  }

  private showErrorMessage(errorResponse: HttpErrorResponse): string {
    if (!errorResponse.error.errorMessage)
      return errorResponse.message; // Default message from HttpErrorResponse
    else
      return errorResponse.error.errorMessage; // Specific error message if available
  }
}
